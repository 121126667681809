/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/popper.js@1.16.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/clipboard@2.0.11/dist/clipboard.min.js
 * - /npm/blueimp-md5@2.12.0/js/md5.min.js
 * - /npm/crypto-js@4.0.0/crypto-js.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
